import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
    getFunctions,
    getPosition,
    consultPosition,
    getCountrys
} from '../services/services';
import Mapa from './mapa';
import WaitingIn from './cola/waitingIn';
import Swal from 'sweetalert2/dist/sweetalert2.js';

moment.locale('es');

const Funciones = ({ moneda, setMoneda, monedas }) => {
    const [countrys, setCountrys] = useState([]);
    const [funciones, setFunciones] = useState([]);
    const [Verificando, setVerificando] = useState(false);
    const [Preloader, setPreloader] = useState(false);
    const [KeyPreloader, setKeyPreloader] = useState(null);
    const [funcionSel, setFuncionSel] = useState(null);
    const [toogleModal, setToogleModal] = useState(null);
    const [toogleWaitlistModal, setToogleWaitlistModal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataSIM, setDataSIM] = useState(null);
    const [latLong, setLatLong] = useState('40.4165,-3.70256');
    const [country, setCountry] = useState('+1');
    const [city, setCity] = useState('Madrid');
    const [followers, setFollowers] = useState('8000');
    const timer = React.useRef();
    const [activeInput, setActiveInput] = useState('email');
    const [email, setEmail] = useState('');
    const [movile, setMovile] = useState('');
    const cola = false;
    useEffect(() => {
        handleFunciones();
        handleCountrys();
    }, []);
    useEffect(() => {
        if ((!cola || (dataSIM && dataSIM.is_allowed)) && funcionSel !== null) {
            if (funcionSel.tipo === 'boton') {
                if (funcionSel.entradas[moneda].trim() !== '') {
                    document.location.href = cola
                        ? `${funcionSel.entradas[moneda]}/?host=${
                            dataSIM.host || 0
                        }`
                        : `${funcionSel.entradas[moneda]}/`;
                    setFuncionSel(null);
                    setDataSIM(null);
                }
            } else {
                console.log(funcionSel);
            }
        }
    }, [dataSIM, funcionSel]);
    const handleFunciones = async () => {
        const data = await getFunctions();
        if (data) {
            setFunciones(data);
        }
        setLoading(false);
    };
    const handleCountrys = async () => {
        const data = await getCountrys();
        if (data) {
            setCountrys(data);
        }
        setLoading(false);
    };

    const handleClick = async (f, key) => {
        if (f?.join === true) {
            setToogleWaitlistModal(true)
            return;
        }
        if (key || (key === 0)) {
            setKeyPreloader(key);
            setPreloader(true);
        }
        setFuncionSel(f);
        if (!dataSIM && cola) {
            setVerificando(true);
            const data = await getPosition(uuidv4(), f.ciudad);
            if (data) {
                setDataSIM(data);
                if (!data.is_allowed) {
                    timer.current = setInterval(async () => {
                        refreshInfo(data.host);
                    }, data.refresh_in_milliseconds);
                }
            } else {
                setDataSIM({ is_allowed: true });
            }
        }
    };
    const refreshInfo = async (host) => {
        let aux = await consultPosition(host);
        if (aux) {
            setDataSIM(aux);
            if (aux.is_allowed) {
                clearInterval(timer.current);
            }
        }
    };

    const getEstatus = (func) => {
        let classCss = '';
        let textBtn = 'Tickets';
        if (func.tipo === 'boton') {
            classCss = func.join === true ? '' : (
                func.entradas[moneda].trim() === '' ||
                func.entradas[moneda].trim() === 'agotado'
                    ? 'agotado'
                    : '');
            textBtn =
                func.entradas[moneda].trim() === ''
                    ? func.textProx
                        ? func.textProx
                        : 'Proximamente'
                    : func.entradas[moneda].trim() === 'agotado'
                        ? func.textAgo
                            ? func.textAgo
                            : 'Agotado'
                        : func.textTk
                            ? func.textTk
                            : 'Tickets';
        } else {
            classCss = func.join === true ? '' : (
                Object.keys(func.entradas[moneda]).filter(
                    (n) =>
                        func.entradas[moneda][n].trim() !== 'agotado' &&
                        func.entradas[moneda][n].trim() !== ''
                ).length === 0
                    ? 'agotado'
                    : '');
            textBtn =
                Object.keys(func.entradas[moneda]).filter(
                    (n) => func.entradas[moneda][n].trim() !== 'agotado'
                ).length > 0
                    ? Object.keys(func.entradas[moneda]).filter(
                        (n) => func.entradas[moneda][n].trim() !== ''
                    ).length > 0
                        ? 'TICKETS'
                        : 'PROXIMAMENTE'
                    : 'AGOTADO';
        }
        return { classCss, textBtn };
    };
    const succes = (b) => {
        setPreloader(false)
        setToogleWaitlistModal(null);
        setToogleModal(null);
        if (b === true) {
            Swal.fire({
                customClass: {
                    title: 'titleCode',
                    footer: `succes_footer`
                },
                icon: "success",
                title: "You’re in!",
                text: "We’re one step closer to bringing our show to your city! We'll keep you posted on updates.",
                footer: 'Never miss a beat! <a href="/pricing">Join the RB Comunity</a>',
                showConfirmButton: false,
                timer: 5000
            });
        }
    }
    const changeCity = (latlong) => {
        setCity(latlong.split(': ')[0]);
        setLatLong(latlong.split(': ')[1]);
        setFollowers(Math.floor(Math.random() * (10000 - 8000) + 8000));
    }

    /* const keyNum = (evt) => {
        let c = (evt.keyCode || evt.which);
        let key = String.fromCharCode(c);
        console.log("0123456789.".search(key) !== -1, key)
        return "0123456789.".search(key) !== -1
    } */
    return loading ? (
        <div></div>
    ) : (
        <section className="ld--wrap-funciones" id="tour-dates">
            <div className="ld--wrap-botones-titles d-flex justify-content-between">
                <h1 className="ld--title">Tour dates</h1>
                {monedas.length > 1 && (
                    <div className="ld--monedas">
                        {monedas.map((m) => (
                            <span
                                key={m}
                                className={moneda === m ? 'active' : ''}
                                onClick={() => setMoneda(m)}
                            >
                                Pagar en {m === 'VES' ? 'VEF' : m}
                            </span>
                        ))}
                    </div>
                )}
            </div>
            <div className="ld--funciones">
                {typeof funciones === 'object' && funciones.map((f, idx) => {
                    const { classCss, textBtn } = getEstatus(f);
                    let ending = moment(
                        moment(f.fecha, 'DD/MM/YYYY h:mm A').format(
                            'YYYY-MM-DD'
                        )
                    ).isBefore(moment().format('YYYY-MM-DD'));
                    return (
                        !ending && (
                            <div
                                className="ld--item-funcion ld--card"
                                key={idx}
                            >
                                <div className="ld--lugar">
                                    <span className="ld--ciudad _new_mar">
                                        <b style={{ fontSize: `48px` }}>
                                            {moment(
                                                f.fecha,
                                                'DD/MM/YYYY hh:mmA'
                                            ).format('DD')}
                                        </b>
                                        <div>
                                            <b>
                                                {moment(
                                                    f.fecha,
                                                    'DD/MM/YYYY hh:mmA'
                                                ).format('MMM')}
                                            </b>
                                            <div>
                                                {moment(
                                                    f.fecha,
                                                    'DD/MM/YYYY hh:mmA'
                                                ).format('YYYY')}
                                            </div>
                                        </div>
                                    </span>
                                    <span className="ld--recinto">
                                    </span>
                                </div>
                                <div className="ld--fecha ld-mw">
                                    <div style={{ color: `white`, fontSize: `20px` }}>{f.ciudad}</div>
                                    <div>
                                        {f.recinto} <span style={{ color: `white` }}>{moment(f.fecha, 'DD/MM/YYYY hh:mmA').format('hh:mm a')}</span>
                                    </div>
                                </div>
                                <div className='ld-emoji'>
                                    {f.emoji}
                                </div>
                                <div className="ld--acciones">
                                    <button
                                        className={`ld--btn ${classCss}`}
                                        style={ f?.join === true ? { background: '#8c53f6' } : {}}
                                        onClick={() => handleClick(f, (idx || 0))}
                                        disabled={ (classCss === 'agotado' || (Verificando && f.id === funcionSel.id))
                                        }>
                                        {
                                            !Verificando && Preloader === true && KeyPreloader === idx ? <div
                                            className="spinner-border text-light"
                                            role="status"></div>
                                            : Verificando &&
                                            f.id === funcionSel.id ? (
                                                    <div
                                                        className="spinner-border text-light"
                                                        role="status"
                                                    >
                                                    </div>
                                                ) : (
                                                    <span>{textBtn}</span>)
                                        }
                                    </button>
                                </div>
                            </div>
                        )
                    );
                })}

                <div className="ld--item-funcion ld--card">
                    <div className="ld--lugar">
                        <span className="ld--ciudad">Don’t see your city on the list?</span>
                    </div>
                    <div className="ld--fecha"> You can start or join a campaign on your city</div>
                    <div className="ld--acciones">
                        <button className={`ld--btn`} style={{ background: '#8c53f6' }} onClick={ () => setToogleModal(true) }>
                            <span>Request</span>
                        </button>
                    </div>
                </div>
                {console.log(city, followers)}
                {
                    toogleModal !== null ? <div className="modalNew d-flex" tabIndex="-1" role="dialog">
                        <div className="wrap">
                            <div className="content">
                                <i className="icon-cerrar" onClick={ () => setToogleModal(null) }></i>
                                <span className='lm--title_new mb-3'>Get involved to bring Rock <br/>Band to your city</span>
                                    <div className='mb-3' style={{ background: `var(--bg__card)`, borderRadius: `6px` }}>
                                        {
                                            latLong ? <iframe
                                                width="100%" style={{ borderRadius: '10px' }} height="200" loading='lazy'
                                                src={ `https://maps.google.com/maps?q=${latLong}&output=embed`}>
                                            </iframe>
                                            : <div style={{ height: "200px" }}></div>
                                        }
                                        <div className='band_cont m-3'>
                                            <input list="brow" style={{ background: `transparent` }} placeholder={city && latLong ? `${city}:, ${latLong}` : ``} type="name" onChange={(e) => changeCity(e.target.value)}/>
                                            <datalist id="brow" >
                                                <option value={`Madrid:, 40.4165,-3.70256`}>Madrid</option>
                                                {
                                                    countrys.map((country, index) => {
                                                        return <option key={index} value={`${country.name}: ${country.latitude},${country.longitude}`}>{country.name}</option>
                                                    })
                                                }
                                            </datalist>
                                        </div>
                                        <div className='band_cont m-3'>
                                            <div className='ig_bang'>
                                                <img className="rb-img" src={'https://cdn.ticketmundo.live/demo-eu/Rock_Band_logo.png'} alt="rock-band-logo" />
                                            </div>
                                            <div className='text_band'>
                                                <b>{followers}</b>
                                                <small>Fans already joined</small>
                                            </div>
                                        </div>
                                    </div>
                                {/* <select onChange={(e) => changeCity(e.target.value)}>
                                    <option value="">Select your city</option>
                                    <option value="Madrid---40.4165,-3.70256">Madrid</option>
                                    <option value="Barcelona---41.38879,2.15899">Barcelona</option>
                                    <option value="Valencia---39.47391,-0.37966">Valencia</option>
                                </select> */}
                                <div>
                                    Join campaign
                                </div>
                                <div className="row">
                                    <div className="col col-12 col-sm-6">
                                        <label htmlFor="">Name</label>
                                        <input type="name" onChange={(e) => changeCity(e.target.value)}/>
                                    </div>
                                    <div className="col col-12 col-sm-6">
                                        <label htmlFor="">
                                            <span onClick={() => setActiveInput('email') } style={ activeInput === 'email' ? { color: `#8C53F6`, cursor: `pointer` } : { opacity: `0.2`, cursor: `pointer` } } >Email</span>
                                            <span style={{ opacity: `0.2` }}> |</span>
                                            <span onClick={() => setActiveInput('mobile') } style={ activeInput === 'mobile' ? { color: `#8C53F6`, cursor: `pointer` } : { opacity: `0.2`, cursor: `pointer` } }> Mobile</span>
                                        </label>
                                        {
                                            activeInput === 'email' ? <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/> : <div style={{ display: `flex` }}>
                                            <input style={{ width: `45px`, borderTopRightRadius: `0`, borderBottomRightRadius: `0` }} type="text" value={country} onChange={(e) => setCountry(e.target.value)}/>
                                            <input style={{ borderTopLeftRadius: `0`, borderBottomLeftRadius: `0` }} type="text" value={movile} onChange={(e) => setMovile((e.target.value).replace(/[^0-9]/g, ''))}/>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <button className='ld--btn' onClick={() => succes(true)}>
                                    <span>Request</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    : null
                }
                {
                    toogleWaitlistModal !== null ? <div className="modalNew d-flex" tabIndex="-1" role="dialog">
                        <div className="wrap">
                            <div className="content">
                                <i className="icon-cerrar" onClick={ () => { succes(false) } }></i>
                                <span className='lm--title_new mb-3'>Be the first to know</span>
                                <div className="row">
                                    <div className="col col-12 ">
                                        <div className='band_cont'>
                                            <div className='ig_bang'>
                                                <img className="rb-img" src={'https://cdn.ticketmundo.live/demo-eu/Rock_Band_logo.png'} alt="rock-band-logo" />
                                            </div>
                                            <div className='text_band'>
                                                <b>Orlando</b>
                                                <small> The Abbey</small>
                                                <small style={{ opacity: `0.2` }} >30-06-2025, 10:00pm</small>
                                            </div>
                                        </div>
                                        <div className='mt-4 mb-4'>
                                            Join the waitlist and be the first to know if more tickets drop!
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-12 col-sm-6">
                                        <label htmlFor="">Name</label>
                                        <input type="name" onChange={(e) => changeCity(e.target.value)}/>
                                    </div>
                                    <div className="col col-12 col-sm-6">
                                        <label htmlFor="">
                                            <span onClick={() => setActiveInput('email') } style={ activeInput === 'email' ? { color: `#8C53F6`, cursor: `pointer` } : { opacity: `0.2`, cursor: `pointer` } } >Email</span>
                                            <span style={{ opacity: `0.2` }}> |</span>
                                            <span onClick={() => setActiveInput('mobile') } style={ activeInput === 'mobile' ? { color: `#8C53F6`, cursor: `pointer` } : { opacity: `0.2`, cursor: `pointer` } }> Mobile</span>
                                        </label>
                                        {
                                            activeInput === 'email' ? <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/> : <div style={{ display: `flex` }}>
                                                <input style={{ width: `45px`, borderTopRightRadius: `0`, borderBottomRightRadius: `0` }} type="text" value={country} onChange={(e) => setCountry(e.target.value)}/>
                                                <input style={{ borderTopLeftRadius: `0`, borderBottomLeftRadius: `0` }} type="text" value={movile} onChange={(e) => setMovile((e.target.value).replace(/[^0-9]/g, ''))}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <button className='ld--btn' onClick={() => succes(true)}>
                                    <span>Waitlist me</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
            {funcionSel &&
                funcionSel.tipo === 'mapa' &&
                (!cola || (dataSIM && !dataSIM.is_allowed)) && (
                <Mapa
                    closeModal={() => {
                        setFuncionSel(null);
                    }}
                    entradas={funcionSel.entradas[moneda]}
                    moneda={moneda}
                    host={dataSIM?.host}
                    onClick={(value) => {
                        console.log(value);
                    }}
                />
            )}
            {funcionSel && dataSIM && !dataSIM.is_allowed && (
                <WaitingIn
                    position={dataSIM.position}
                    time={dataSIM.eta_in_seconds}
                    callback={() => refreshInfo(dataSIM.host)}
                />
            )}
        </section>
    );
};

export default Funciones;
