import React, { useState } from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/dist/sweetalert2.css';
import '../styles/fanbase.scss';
import {
    Header,
    Footer
} from '../components';
import {
    ButtonLogin
} from "@TicketMundo/login-tm-library";

const fanbase = () => {
    const [yearly, setYearly] = useState(false);

    let checked = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
        <path d="M17.75 2.66665L7.05 13.6666L0.25 6.66665L2.63333 4.33331L7.05 8.88331L15.3667 0.333313L17.75 2.66665Z" fill="#009FFF"/>
    </svg>;
    let unchecked = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M2.4985 0.166695L0.165169 2.50003L5.63184 8.00003L0.131836 13.5L2.4985 15.8334L7.9985 10.3667L13.4985 15.8667L15.8318 13.5L10.3652 8.00003L15.8652 2.50003L13.4985 0.166695L7.9985 5.63336L2.4985 0.133362V0.166695Z" fill="#8E8E93"/>
    </svg>
    let icon = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
        <path d="M11.3682 2.28306L4.94816 8.88306L0.868164 4.68306L2.29816 3.28306L4.94816 6.01306L9.93816 0.883057L11.3682 2.28306Z" fill="white"/>
    </svg>

    let icono = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18">
        <path d="M5.7 4.3 4.3 5.7 7.58 9l-3.3 3.3 1.42 1.4L9 10.42l3.3 3.3 1.4-1.42L10.42 9l3.3-3.3-1.42-1.4L9 7.58l-3.3-3.3Z"></path>
    </svg>

    let icoInfo = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18" fill="#868993">
        <path d="M9 17A8 8 0 1 0 9 1a8 8 0 0 0 0 16Zm1-12a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM8.5 9.5H7V8h3v6H8.5V9.5Z"></path>
    </svg>

    return (
        <section>
            <Header smallHeader={true} backTo={'/'}/>
            <div className='container'>
                <div className='header_fan'>
                    <a href='/'>
                        <img className="rb-img" src={'https://cdn.ticketmundo.live/demo-eu/Rock_Band_logo.png'} alt="rock-band-logo" />
                    </a>
                    <span className='title_fan'>How big of a <span style={{ color: `#8C53F6` }}>fan</span> are you?</span>
                    <p>Joining our fan community  and get  updated with all the latest news! If you're looking for more, subscribe to unlock exclusive content, special deals, and offers—it's our way of rewarding our most dedicated fans</p>
                    <div style={{ justifyContent: `center`, display: `flex`, gap: `16px`, alignItems: `center` }}>
                        <label style={{ fontWeight: `bold`, justifyContent: `center`, display: `flex`, gap: `8px`, alignItems: `center`, cursor: `pointer` }}>
                            <div style={yearly !== true ? {} : { opacity: `0.5` }}>Monthly</div>
                            <label className="switch">
                                <input type="checkbox" checked={yearly} onChange={ () => setYearly(!yearly) }/>
                                <span className="slider round"></span>
                            </label>
                            <div style={yearly !== true ? { opacity: `0.5` } : {} }>Yearly</div>
                        </label>
                        <span className='ahor'> 16% annual discount. 😍</span>
                    </div>
                </div>
                <ul className='pricing_cont'>
                    <li>
                        <div style={ yearly ? { height: `403.8px` } : {} }>
                            <div>
                                <img src={'https://cdn.ticketmundo.live/commons/follower.svg'} alt="Follower" />
                                <span>
                                    <p>Follower</p>
                                    <h1>FREE</h1>
                                </span>
                            </div>
                            <label className="ld--btn">Join <span style={{ display: `none` }}><ButtonLogin/></span></label>
                            </div>
                        <ul>
                            <li>
                                {icon} Access to updates and news from Rock Band.
                            </li>
                            <li>
                                {icon} Limited access to behind-the-scenes content (e.g., one post per month).

                            </li>
                            <li>
                                {icon} Early announcements for upcoming events and releases.
                            </li>
                            <li>
                                {icon} Ability to RSVP for free events or livestreams.
                            </li>

                            <li className='color_li'>
                                {icono} Exclusive behind-the-scenes content (weekly updates).
                            </li>
                            <li className='color_li'>
                                {icono} Access to digital collectibles or downloads (e.g., wallpapers, tracks).
                            </li>
                            <li className='color_li'>
                                {icono} Priority access to event tickets or pre-sales.
                            </li>
                            <li className='color_li'>
                                {icono} One exclusive Q&A session per month with us.
                            </li>
                            <li className='color_li'>
                                {icono} Discounts on merch and exclusive items.
                            </li>
                            <li className='color_li'>
                                {icono} Full access to premium content, including private livestreams or videos.
                            </li>
                            <li className='color_li'>
                                {icono} Monthly personalized shoutouts or messages from us.
                            </li>
                            <li className='color_li'>
                                {icono} Access to exclusive merch or limited-edition items.
                            </li>
                            <li className='color_li'>
                                {icono} Invitations to VIP virtual events or meet-and-greets.
                            </li>
                            <li className='color_li'>
                                {icono} Early access to unreleased music or projects.
                            </li>
                            <li className='color_li'>
                                {icono} Free shipping on merch purchases.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div>
                            <img src={'https://cdn.ticketmundo.live/commons/supporter.svg'} alt="Supporter" />
                            {
                                yearly !== true ? <>
                                    <span>
                                        <p>Supporter</p>
                                        <h1>$9.99<small className='color_li'>/ Monthly</small></h1>
                                    </span>
                                <label className="ld--btn">Join <span style={{ display: `none` }}><ButtonLogin/></span></label>
                                </>
                                : <>
                                    <span>
                                        <p>Supporter</p>
                                        <h1>$8.39<small className='color_li'>/ Monthly</small></h1>
                                        <span className='color_li'>$100.68 / Yearly</span>
                                        <span className='ahor'>You save $19.20 a year
                                            <label data-title="Compared to paying monthly. The full monthly price is $9.99. The monthly price within the yearly subscription is $8.39">{icoInfo}</label>
                                        </span>
                                    </span>
                                    <label className="ld--btn">Join <span style={{ display: `none` }}><ButtonLogin/></span></label>
                                </>
                            }
                        </div>
                        <ul>
                            <li>
                                {icon} Access to updates and news from Rock Band.
                            </li>
                            <li>
                                {icon} Limited access to behind-the-scenes content (e.g., one post per month).

                            </li>
                            <li>
                                {icon} Early announcements for upcoming events and releases.
                            </li>
                            <li>
                                {icon} Ability to RSVP for free events or livestreams.
                            </li>
                            <li>
                                {icon} Exclusive behind-the-scenes content (weekly updates).
                            </li>
                            <li>
                                {icon} Access to digital collectibles or downloads (e.g., wallpapers, tracks).
                            </li>
                            <li>
                                {icon} Priority access to event tickets or pre-sales.
                            </li>
                            <li>
                                {icon} One exclusive Q&A session per month with us.
                            </li>
                            <li>
                                {icon} Discounts on merch and exclusive items.
                            </li>

                            <li className='color_li'>
                                {icono} Full access to premium content, including private livestreams or videos.
                            </li>
                            <li className='color_li'>
                                {icono} Monthly personalized shoutouts or messages from us.
                            </li>
                            <li className='color_li'>
                                {icono} Access to exclusive merch or limited-edition items.
                            </li>
                            <li className='color_li'>
                                {icono} Invitations to VIP virtual events or meet-and-greets.
                            </li>
                            <li className='color_li'>
                                {icono} Early access to unreleased music or projects.
                            </li>
                            <li className='color_li'>
                                {icono} Free shipping on merch purchases.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div>
                            <img src={'https://cdn.ticketmundo.live/commons/groupie.svg'} alt="Groupie" />
                            {
                                yearly !== true ? <>
                                    <span>
                                        <p>Groupie</p>
                                        <h1>$19.99<small className='color_li'>/ Monthly</small></h1>
                                    </span>
                                <label className="ld--btn">Join <span style={{ display: `none` }}><ButtonLogin/></span></label>
                                </>
                                : <>
                                    <span>
                                        <p>Groupie</p>
                                        <h1>$17.79<small className='color_li'>/ Monthly</small></h1>
                                        <span className='color_li'>184.70$ / Yearly</span>
                                        <span className='ahor'>You save $55.18 a year
                                            <label data-title="Compared to paying monthly. The full monthly price is $19.99. The monthly price within the yearly subscription is $17.79.">{icoInfo}</label>
                                        </span>
                                    </span>
                                <label className="ld--btn">Join <span style={{ display: `none` }}><ButtonLogin/></span></label>
                                </>
                            }
                        </div>
                        <ul>
                            <li>
                                {icon} Access to updates and news from Rock Band.
                            </li>
                            <li>
                                {icon} Limited access to behind-the-scenes content (e.g., one post per month).

                            </li>
                            <li>
                                {icon} Early announcements for upcoming events and releases.
                            </li>
                            <li>
                                {icon} Ability to RSVP for free events or livestreams.
                            </li>
                            <li>
                                {icon} Exclusive behind-the-scenes content (weekly updates).
                            </li>
                            <li>
                                {icon} Access to digital collectibles or downloads (e.g., wallpapers, tracks).
                            </li>
                            <li>
                                {icon} Priority access to event tickets or pre-sales.
                            </li>
                            <li>
                                {icon} One exclusive Q&A session per month with us.
                            </li>
                            <li>
                                {icon} Discounts on merch and exclusive items.
                            </li>
                            <li>
                                {icon} Full access to premium content, including private livestreams or videos.
                            </li>
                            <li>
                                {icon} Monthly personalized shoutouts or messages from us.
                            </li>
                            <li>
                                {icon} Access to exclusive merch or limited-edition items.
                            </li>
                            <li>
                                {icon} Invitations to VIP virtual events or meet-and-greets.
                            </li>
                            <li>
                                {icon} Early access to unreleased music or projects.
                            </li>
                            <li>
                                {icon} Free shipping on merch purchases.
                            </li>
                        </ul>
                    </li>
                </ul>
                <h2 className='ahor_t'>Compare features per plan</h2>
                <ul className='pricing_cont2'>
                    <li className='title_cont2'>
                       <div>Feature</div>
                        <ul>
                            <li> Access to updates and news from Rock Band.</li>
                            <li> Limited access to behind-the-scenes content (e.g., one post per month).</li>
                            <li> Early announcements for upcoming events and releases.</li>
                            <li> Ability to RSVP for free events or livestreams.</li>
                            <li> Exclusive behind-the-scenes content (weekly updates).</li>
                            <li>Access to digital collectibles or downloads (e.g., wallpapers, tracks).</li>
                            <li>Priority access to event tickets or pre-sales.</li>
                            <li>One exclusive Q&A session per month with us.</li>
                            <li>Discounts on merch and exclusive items.</li>
                            <li>Full access to premium content, including private livestreams or videos.</li>
                            <li>Monthly personalized shoutouts or messages from us.</li>
                            <li>Access to exclusive merch or limited-edition items.</li>
                            <li>Invitations to VIP virtual events or meet-and-greets.</li>
                            <li>Early access to unreleased music or projects.</li>
                            <li>Free shipping on merch purchases.</li>
                       </ul>
                    </li>
                    <li>
                        <div>Follower</div>
                        <ul>
                            <li>{checked}<span> Access to updates and news from Rock Band.</span></li>
                            <li>{checked}<span> Limited access to behind-the-scenes content (e.g., one post per month).</span></li>
                            <li>{checked}<span> Early announcements for upcoming events and releases.</span></li>
                            <li>{checked}<span> Ability to RSVP for free events or livestreams.</span></li>
                            <li>{checked}<span> Exclusive behind-the-scenes content (weekly updates).</span></li>
                            <li>{unchecked}<span> Access to digital collectibles or downloads (e.g., wallpapers, tracks).</span></li>
                            <li>{unchecked}<span> Priority access to event tickets or pre-sales.</span></li>
                            <li>{unchecked}<span> One exclusive Q&A session per month with us.</span></li>
                            <li>{unchecked}<span> Discounts on merch and exclusive items.</span></li>
                            <li>{unchecked}<span> Full access to premium content, including private livestreams or videos.</span></li>
                            <li>{unchecked}<span> Monthly personalized shoutouts or messages from us.</span></li>
                            <li>{unchecked}<span> Access to exclusive merch or limited-edition items.</span></li>
                            <li>{unchecked}<span> Invitations to VIP virtual events or meet-and-greets.</span></li>
                            <li>{unchecked}<span> Early access to unreleased music or projects.</span></li>
                            <li>{unchecked}<span> Free shipping on merch purchases.</span></li>
                       </ul>
                    </li>
                    <li>
                        <div>Supporter</div>
                       <ul>
                        <li>{checked}<span> Access to updates and news from Rock Band.</span></li>
                        <li>{checked}<span> Limited access to behind-the-scenes content (e.g., one post per month).</span></li>
                        <li>{checked}<span> Early announcements for upcoming events and releases.</span></li>
                        <li>{checked}<span> Ability to RSVP for free events or livestreams.</span></li>
                        <li>{checked}<span> Exclusive behind-the-scenes content (weekly updates).</span></li>
                        <li>{checked}<span> Access to digital collectibles or downloads (e.g., wallpapers, tracks).</span></li>
                        <li>{checked}<span> Priority access to event tickets or pre-sales.</span></li>
                        <li>{checked}<span> One exclusive Q&A session per month with us.</span></li>
                        <li>{checked}<span> Discounts on merch and exclusive items.</span></li>
                        <li>{unchecked}<span> Full access to premium content, including private livestreams or videos.</span></li>
                        <li>{unchecked}<span> Monthly personalized shoutouts or messages from us.</span></li>
                        <li>{unchecked}<span> Access to exclusive merch or limited-edition items.</span></li>
                        <li>{unchecked}<span> Invitations to VIP virtual events or meet-and-greets.</span></li>
                        <li>{unchecked}<span> Early access to unreleased music or projects.</span></li>
                        <li>{unchecked}<span> Free shipping on merch purchases.</span></li>
                       </ul>
                    </li>
                    <li>
                        <div>Groupie</div>
                       <ul>
                        <li>{checked}<span> Access to updates and news from Rock Band.</span></li>
                        <li>{checked}<span> Limited access to behind-the-scenes content (e.g., one post per month).</span></li>
                        <li>{checked}<span> Early announcements for upcoming events and releases.</span></li>
                        <li>{checked}<span> Ability to RSVP for free events or livestreams.</span></li>
                        <li>{checked}<span> Exclusive behind-the-scenes content (weekly updates).</span></li>
                        <li>{checked}<span> Access to digital collectibles or downloads (e.g., wallpapers, tracks).</span></li>
                        <li>{checked}<span> Priority access to event tickets or pre-sales.</span></li>
                        <li>{checked}<span> One exclusive Q&A session per month with us.</span></li>
                        <li>{checked}<span> Discounts on merch and exclusive items.</span></li>
                        <li>{checked}<span> Full access to premium content, including private livestreams or videos.</span></li>
                        <li>{checked}<span> Monthly personalized shoutouts or messages from us.</span></li>
                        <li>{checked}<span> Access to exclusive merch or limited-edition items.</span></li>
                        <li>{checked}<span> Invitations to VIP virtual events or meet-and-greets.</span></li>
                        <li>{checked}<span> Early access to unreleased music or projects.</span></li>
                        <li>{checked}<span> Free shipping on merch purchases.</span></li>
                       </ul>
                    </li>
                </ul>
            </div>
            <Footer />
        </section>
    );
};

export default fanbase;
