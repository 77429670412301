import { TMLoginProvider, getLocalStorage } from "@TicketMundo/login-tm-library";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { store, persistor } from './tienda/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

function loginCallback (user) {
    const storageToken = getLocalStorage("token");
    const token = storageToken?.Value;
    console.log("storageToken-------------------->", storageToken);
    console.log("token--------------------------->", token);
    if (token) {
      document.cookie = `token=${token} max-age=10; domain=ticketmundo.com`;
    }
    console.log("cookies--------------------------->", document.cookie.split(";"));
    sessionStorage.setItem("session", JSON.stringify(user));
}
function logoutCallback () {
    sessionStorage.clear();
    window.location.search = '';
}

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <TMLoginProvider
            options={{
                ui: {
                  disabledLoginWithGoogle: false,
                  activeDarkMode: true,
                  primary_color: `#009FFF`
                },
                appConfig: {
                    name: global?.CompanyName ? global.CompanyName : "Rock band",
                    company_id: global?.CompanyId ? global.CompanyId : 2,
                    // country_id: global?.Country ? global.Country : undefined,
                    lang: 'en'
                },
              }}
            loginCallback={loginCallback} logoutCallback={logoutCallback}>
                <PersistGate loading={null} persistor={persistor}>
                    <App />
                </PersistGate>
            </TMLoginProvider>
        </Provider>
    </React.StrictMode>
);
