import React from 'react';

const Footer = () => {
    return (
        <footer className="ld--footer">
            <div className="container">
                <small style={{ marginLeft: '6px' }}>v2.34
                </small>
                <span className='t_p_footer_cont'>
                    <a href="https://www.ticketmundo.com/privacy-policies">Terms</a>
                    |
                    <a href="https://www.ticketmundo.com/terms-use">Privacy</a>
                    {/* <img
                        className="ld--logo-tm"
                        src={
                            process.env.REACT_APP_URL_IMAGES_COMMONS +
                            'agte.svg'
                        }
                        alt="AGTE"
                    /> */}
                </span>
                <img
                    className="ld--logo-tm"
                    src={`${process.env.REACT_APP_URL_IMAGES_COMMONS}logo-footer.svg`}
                    alt="Ticketmundo"
                />
            </div>
        </footer>
    );
};

export default Footer;
